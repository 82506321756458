import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AWS from 'aws-sdk';

const TodoList = ({ username, onAddEvent }) => {
  const [lists, setLists] = useState([]);
  const [newListName, setNewListName] = useState('');
  const [newTaskName, setNewTaskName] = useState('');
  const [selectedListId, setSelectedListId] = useState(null);

  useEffect(() => {
    fetchLists();
  }, [username]);

  const fetchLists = async () => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: 'UserTodoLists',
      KeyConditionExpression: 'username = :username',
      ExpressionAttributeValues: {
        ':username': username,
      },
    };

    try {
      const result = await dynamodb.query(params).promise();
      setLists(result.Items[0]?.lists || []);
    } catch (error) {
      console.error('Error fetching lists:', error);
    }
  };

  const saveLists = async (updatedLists) => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: 'UserTodoLists',
      Item: {
        username: username,
        lists: updatedLists,
      },
    };

    try {
      await dynamodb.put(params).promise();
    } catch (error) {
      console.error('Error saving lists:', error);
    }
  };

  const addList = () => {
    if (newListName.trim() === '') return;
    const newList = {
      id: Date.now().toString(),
      name: newListName,
      tasks: [],
    };
    const updatedLists = [...lists, newList];
    setLists(updatedLists);
    saveLists(updatedLists);
    setNewListName('');
  };

  const addTask = () => {
    if (newTaskName.trim() === '' || !selectedListId) return;
    const updatedLists = lists.map(list => {
      if (list.id === selectedListId) {
        return {
          ...list,
          tasks: [...list.tasks, { id: Date.now().toString(), name: newTaskName }],
        };
      }
      return list;
    });
    setLists(updatedLists);
    saveLists(updatedLists);
    setNewTaskName('');
  };

  const onDragStart = (start) => {
    const task = lists.find(list => list.id === start.source.droppableId)
                      .tasks.find(t => t.id === start.draggableId);
    window.localStorage.setItem('draggedTask', task.name);
  };

  const onDragEnd = (result) => {
    window.localStorage.removeItem('draggedTask');
    if (!result.destination) return;

    const sourceListId = result.source.droppableId;
    const destListId = result.destination.droppableId;
    const taskId = result.draggableId;

    if (destListId === 'calendar') {
      // Task dropped on calendar (handled by UserCalendar component)
      const task = lists.find(list => list.id === sourceListId).tasks.find(t => t.id === taskId);

      // Remove task from the list
      const updatedLists = lists.map(list => {
        if (list.id === sourceListId) {
          return {
            ...list,
            tasks: list.tasks.filter(t => t.id !== taskId),
          };
        }
        return list;
      });

      setLists(updatedLists);
      saveLists(updatedLists);
    } else {
      // Task moved within todo lists
      const updatedLists = lists.map(list => {
        if (list.id === sourceListId) {
          return {
            ...list,
            tasks: list.tasks.filter(task => task.id !== taskId),
          };
        }
        if (list.id === destListId) {
          const [reorderedTask] = lists
            .find(l => l.id === sourceListId)
            .tasks.filter(task => task.id === taskId);
          return {
            ...list,
            tasks: [
              ...list.tasks.slice(0, result.destination.index),
              reorderedTask,
              ...list.tasks.slice(result.destination.index),
            ],
          };
        }
        return list;
      });

      setLists(updatedLists);
      saveLists(updatedLists);
    }
  };

  return (
    <div className="todo-list">
      <h2>Todo List</h2>
      <div className="add-list">
        <input
          type="text"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
          placeholder="New list name"
        />
        <button onClick={addList}>Add List</button>
      </div>
      <div className="add-task">
        <input
          type="text"
          value={newTaskName}
          onChange={(e) => setNewTaskName(e.target.value)}
          placeholder="New task name"
        />
        <select
          value={selectedListId || ''}
          onChange={(e) => setSelectedListId(e.target.value)}
        >
          <option value="">Select a list</option>
          {lists.map(list => (
            <option key={list.id} value={list.id}>{list.name}</option>
          ))}
        </select>
        <button onClick={addTask}>Add Task</button>
      </div>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <div className="lists-container">
          {lists.map(list => (
            <Droppable droppableId={list.id} key={list.id}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="list"
                >
                  <h3>{list.name}</h3>
                  {list.tasks.map((task, index) => (
                    <Draggable key={task.id} draggableId={task.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="task"
                        >
                          {task.name}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
    </div>
  );
};

export default TodoList;