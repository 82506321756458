import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const UserCalendar = ({ username, events, onEventAdd, onEventChange }) => {
  const handleSelect = ({ start, end }) => {
    const title = window.prompt('New Event name');
    if (title) {
      const newEvent = { id: Date.now().toString(), start, end, title };
      onEventAdd(newEvent);
    }
  };

  const moveEvent = ({ event, start, end }) => {
    onEventChange({ ...event, start, end });
  };

  const resizeEvent = ({ event, start, end }) => {
    onEventChange({ ...event, start, end });
  };

  const onDropFromOutside = ({ start, end, allDay }) => {
    const title = window.localStorage.getItem('draggedTask');
    if (title) {
      const newEvent = { id: Date.now().toString(), start, end, title };
      onEventAdd(newEvent);
      window.localStorage.removeItem('draggedTask');
    }
  };

  return (
    <div style={{ height: 'calc(100vh - 100px)', margin: '20px' }}>
      <DnDCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectSlot={handleSelect}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        resizable
        selectable
        defaultView="week"
        style={{ height: '100%' }}
        onDropFromOutside={onDropFromOutside}
        dragFromOutsideItem={() => ({ title: window.localStorage.getItem('draggedTask') })}
      />
    </div>
  );
};

export default UserCalendar;