import React, { useState } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { COGNITO_CONFIG } from './cognitoConfig';

const Login = ({ setIsAuthenticated, setUsername: setAppUsername, onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [error, setError] = useState('');
  const [cognitoUser, setCognitoUser] = useState(null);

  const userPool = new CognitoUserPool({
    UserPoolId: COGNITO_CONFIG.userPoolId,
    ClientId: COGNITO_CONFIG.clientId,
  });

  const handleLogin = (e) => {
    e.preventDefault();

    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    setCognitoUser(cognitoUser);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const accessToken = result.getAccessToken().getJwtToken();
        const idToken = result.getIdToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('refreshToken', refreshToken);

        console.log('Login success:', result);
        setIsAuthenticated(true);
        setAppUsername(username);
        setError('');
        onLoginSuccess(result); // Call the onLoginSuccess callback
      },
      onFailure: (err) => {
        console.error('Login failed:', err.message || JSON.stringify(err));
        setError(err.message || 'An error occurred during login');
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        setIsNewPasswordRequired(true);
        setError('You need to reset your password.');
      },
    });
  };

  const handleNewPassword = (e) => {
    e.preventDefault();

    if (!cognitoUser) {
      setError('User session is invalid. Please log in again.');
      return;
    }

    cognitoUser.completeNewPasswordChallenge(newPassword, null, {
      onSuccess: (result) => {
        console.log('Password reset success:', result);
        setIsAuthenticated(true);
        setAppUsername(username);
        setIsNewPasswordRequired(false);
        setError('');
        onLoginSuccess(result); // Call the onLoginSuccess callback
      },
      onFailure: (err) => {
        console.error('Password reset failed:', err.message || JSON.stringify(err));
        setError(err.message || 'An error occurred during password reset');
      },
    });
  };

  return (
    <div className="login-container">
      {!isNewPasswordRequired ? (
        <form onSubmit={handleLogin}>
          <h2>Login</h2>
          <div>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button type="submit">Login</button>
        </form>
      ) : (
        <form onSubmit={handleNewPassword}>
          <h2>Reset Password</h2>
          <div>
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button type="submit">Set New Password</button>
        </form>
      )}
    </div>
  );
};

export default Login;