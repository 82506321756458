import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { CognitoIdentityCredentials } from 'aws-sdk';
import AWS from 'aws-sdk';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { COGNITO_CONFIG } from './cognitoConfig';
import Login from './Login';
import ResetPassword from './ResetPassword';
import TodoList from './TodoList';
import UserCalendar from './UserCalendar';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userPool = new CognitoUserPool({
    UserPoolId: COGNITO_CONFIG.userPoolId,
    ClientId: COGNITO_CONFIG.clientId,
  });

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.getSession((err, session) => {
        if (err) {
          console.error('Session error:', err);
          setLoading(false);
          return;
        }
        if (session.isValid()) {
          setIsAuthenticated(true);
          setUsername(currentUser.getUsername());
          refreshAWSCredentials(session)
            .then(() => fetchEvents(currentUser.getUsername()))
            .catch(err => {
              console.error('Error refreshing credentials:', err);
              setError('Failed to authenticate. Please try logging in again.');
            })
            .finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  };

  const refreshAWSCredentials = async (session) => {
    const loginKey = `cognito-idp.${COGNITO_CONFIG.region}.amazonaws.com/${COGNITO_CONFIG.userPoolId}`;
    const logins = {};
    logins[loginKey] = session.getIdToken().getJwtToken();

    AWS.config.region = COGNITO_CONFIG.region;
    AWS.config.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: COGNITO_CONFIG.identityPoolId,
      Logins: logins
    });

    return new Promise((resolve, reject) => {
      (AWS.config.credentials).refresh((error) => {
        if (error) {
          console.error('Error refreshing credentials:', error);
          reject(error);
        } else {
          console.log('Successfully refreshed credentials');
          resolve();
        }
      });
    });
  };

  const fetchEvents = async (username) => {
    setLoading(true);
    setError(null);

    const dynamodb = new AWS.DynamoDB.DocumentClient();

    const params = {
      TableName: 'UserEvents',
      KeyConditionExpression: 'username = :username',
      ExpressionAttributeValues: {
        ':username': username,
      },
    };

    try {
      const result = await dynamodb.query(params).promise();
      setEvents(result.Items.map(item => ({
        id: item.eventId,
        start: new Date(item.start),
        end: new Date(item.end),
        title: item.title,
      })));
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to fetch events. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddEvent = async (newEvent) => {
    const updatedEvents = [...events, newEvent];
    setEvents(updatedEvents);

    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: 'UserEvents',
      Item: {
        username: username,
        eventId: newEvent.id,
        start: newEvent.start.toISOString(),
        end: newEvent.end.toISOString(),
        title: newEvent.title,
      },
    };

    try {
      await dynamodb.put(params).promise();
    } catch (error) {
      console.error('Error saving event:', error);
      setError('Failed to save event. Please try again.');
      setEvents(events); // Revert to previous state if save fails
    }
  };

  const handleEventChange = async (changedEvent) => {
    const updatedEvents = events.map(event => 
      event.id === changedEvent.id ? changedEvent : event
    );
    setEvents(updatedEvents);

    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: 'UserEvents',
      Item: {
        username: username,
        eventId: changedEvent.id,
        start: changedEvent.start.toISOString(),
        end: changedEvent.end.toISOString(),
        title: changedEvent.title,
      },
    };

    try {
      await dynamodb.put(params).promise();
    } catch (error) {
      console.error('Error updating event:', error);
      setError('Failed to update event. Please try again.');
      setEvents(events); // Revert to previous state if update fails
    }
  };

  const handleLoginSuccess = (result) => {
    setIsAuthenticated(true);
    setUsername(result.getIdToken().payload.email);
    checkAuthStatus();
  };

  const handleLogout = () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
      setIsAuthenticated(false);
      setUsername('');
      setEvents([]);
      AWS.config.credentials = null;
    }
  };

  const UserDashboard = () => (
    <DragDropContext>
      <div className="dashboard">
        <div className="todo-list-container">
          <TodoList username={username} onAddEvent={handleAddEvent} />
        </div>
        <Droppable droppableId="calendar">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="calendar-container"
            >
              <h2>{username}'s Calendar</h2>
              <UserCalendar
                username={username}
                events={events}
                onEventAdd={handleAddEvent}
                onEventChange={handleEventChange}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );

  if (loading) return <div>Loading...</div>;

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>Welcome to Amu and Banyu's Website</h1>
          {isAuthenticated && (
            <nav>
              <Link to="/dashboard">Dashboard</Link> |{' '}
              <button onClick={handleLogout}>Logout</button>
            </nav>
          )}
        </header>

        {error && <div className="error-message">{error}</div>}

        <Routes>
          <Route path="/" element={
            isAuthenticated ? (
              <Navigate to="/dashboard" />
            ) : (
              <Login 
                setIsAuthenticated={setIsAuthenticated} 
                setUsername={setUsername} 
                onLoginSuccess={handleLoginSuccess}
              />
            )
          } />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/dashboard"
            element={isAuthenticated ? <UserDashboard /> : <Navigate to="/" />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;