import React, { useState } from 'react';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { COGNITO_CONFIG } from './cognitoConfig';

const ResetPassword = () => {
  const [username, setUsername] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');

  const userPool = new CognitoUserPool({
    UserPoolId: COGNITO_CONFIG.userPoolId,
    ClientId: COGNITO_CONFIG.clientId,
  });

  const handleForgotPassword = (e) => {
    e.preventDefault();
    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        console.log('Verification code sent:', result);
        setStep(2);
        setError('');
      },
      onFailure: (err) => {
        console.error('Password reset request failed:', err.message || JSON.stringify(err));
        setError(err.message || 'An error occurred');
      },
    });
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        console.log('Password reset successful');
        setError('');
        setStep(3);
      },
      onFailure: (err) => {
        console.error('Password reset failed:', err.message || JSON.stringify(err));
        setError(err.message || 'An error occurred during password reset');
      },
    });
  };

  return (
    <div className="reset-password-container">
      {step === 1 ? (
        <form onSubmit={handleForgotPassword}>
          <h2>Reset Password</h2>
          <div>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button type="submit">Send Verification Code</button>
        </form>
      ) : step === 2 ? (
        <form onSubmit={handlePasswordReset}>
          <h2>Enter Verification Code and New Password</h2>
          <div>
            <label htmlFor="verificationCode">Verification Code:</label>
            <input
              type="text"
              id="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button type="submit">Reset Password</button>
        </form>
      ) : (
        <div>
          <h2>Password Reset Successful</h2>
          <p>You can now log in with your new password.</p>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;